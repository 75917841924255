import store from '@/store'
import { ref } from '@vue/composition-api'
import http from '@/global/http'
import util from '@/global/util'

export default function useUser() {
  const pageMode = ref('')
  const { parseFieldsToNumber } = util

  const formatClientData = (clientData = {}) => {
    // những trường này sẽ được chuyển thành kiểu number
    const NumberField = [
      'limit_price',
      'limit_office_price',
      'limit_townhouse_hire_price_show_phone',
      'limit_townhouse_sell_price_show_phone',
      'limit_townhouse_hire_price_show_product',
      'limit_townhouse_sell_price_show_product',
    ]

    return parseFieldsToNumber(clientData, NumberField)
  }

  const fetchUser = (id, cb) => http.handle(store.dispatch('user/fetchUser', { id }), cb)
  const updateUser = (data, cb) => http.handle(store.dispatch('user/updateUser', formatClientData(data)), cb)
  const deleteUser = (id, cb) => http.handle(store.dispatch('user/deleteUser', { id }), cb)
  const updateUserLimit = data => http.handle(store.dispatch('user/updateUserLimit', formatClientData(data)))

  return {
    pageMode,
    fetchUser,
    updateUser,
    updateUserLimit,
    deleteUser,
  }
}
